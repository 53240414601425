// obj key
// refreshToken vux中刷新token的函数
// filterUrl Array 不需要拦截的请求路径
import { getToken, getUserId } from './auth';
import CryptoJS from '/public/crypto-js';

const ReRegExp = require('reregexp').default;

export function interceptors(axios, store, router, obj) {
  // 防止重复刷新
  let isRefreshing = false;
  // 缓存token失效后 所有接口
  let subscribers = [];

  // 刷新 token 后, 将缓存的接口重新请求一次
  function onAccessTokenFetched(newToken) {
    subscribers.forEach((callback) => {
      callback(newToken);
    });
    // 清空缓存接口
    subscribers = [];
  }

  // 添加缓存接口
  function addSubscriber(callback) {
    subscribers.push(callback);
  }

  // 添加请求拦截器
  axios.interceptors.request.use(
    (config) => {
      // 在发送请求之前做些什么
      config.Authorization = getToken();
      config.headers.requestSource = 'MOBILE_WEB';
      config.headers.userId = getUserId();
      config.headers['X-Token'] = getToken();
      if (config.url.indexOf('wechatlogin') < 0 && config.url.indexOf('userLogin') < 0) {
        config.headers.Authorization = `bearer ${getToken()}`;
      }
      const time = new Date();
      const r1 = new ReRegExp(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-4[a-fA-F0-9]{3}-y[a-fA-F0-9]{3}-[a-fA-F0-9]{12}/);
      const nonce = r1.build();
      const requestTime = time.getTime() + (time.getTimezoneOffset() * 60000); // 设置时区
      const signstring = `timestamp=${requestTime}&nonce=${nonce}`;
      const hash = CryptoJS.MD5(signstring);
      config.headers.Requesttime = requestTime;
      config.headers.Nonce = nonce;
      config.headers.Sign = hash.toString(CryptoJS.enc.Hex);
      // todo: mock数据
      // if (config.url.indexOf('1456055-0-default') !== -1) {
      //     if (!config.params) {
      //         config.params = {};
      //     }
      //     config.params.apifoxToken = '03gFe1ppk96pIwc5rwUiF1zwhLJ4ni1D';
      // }
      return config;
    },
    (error) => {
      // 对请求错误做些什么
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use((response) => {
    const fliterFlag = obj.filtersUrl
      ? !obj.filtersUrl.some((item) => response.config.url.includes(item))
      : true;
    if (response.data.code === 'A0230' && fliterFlag) {
      if (!isRefreshing) {
        isRefreshing = true;
        // 将刷新token的方法放在vuex中处理了, 可见下面区块代码
        store
          .dispatch(obj.refreshToken)
          .then((res) => {
            if (res.code === '0000000000') {
              // 当刷新成功后, 重新发送缓存请求
              onAccessTokenFetched(res.result.jwt_token);
            } else {
              store.dispatch('user/logoutClear');
              // router.push('/login?redirect=dashboard');
            }
          })
          .catch(() => {
            // 刷新token报错的话, 就需要跳转到登录页面
            // window.location = '/#/guide/login';
            store.dispatch('user/logoutClear');
            // router.push('/login?redirect=dashboard');
          })
          .finally(() => {
            isRefreshing = false;
          });
      }
      return new Promise((resolve) => {
        // 这里是将其他接口缓存起来的关键, 返回Promise并且让其状态一直为等待状态,
        // 只有当token刷新成功后, 就会调用通过addSubscriber函数添加的缓存接口,
        // 此时, Promise的状态就会变成resolve
        addSubscriber((newToken) => {
          // 表示用新的token去替换掉原来的token
          response.config.headers.Authorization = `bearer ${newToken}`;
          // 替换掉url -- 因为baseURL会扩展请求url
          // response.config.url = response.config.url.replace(response.config.baseURL, '');
          // 用重新封装的config去请求, 就会将重新请求后的返回
          resolve(axios(response.config));
        });
      });
    }
    // todo： mock数据
    // if (response.config.url.indexOf('1456055-0-default') !== -1) {
    //     response.data.code = '200';
    // }
    return response.data;
  });
}
