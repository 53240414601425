import axios from 'axios';

export function login(data) {
  return axios.post('/oauth/userLogin', data, { params: data });
  // noToken: true
}

export function refreshToken(data) {
  return axios.post('/oauth/refreshToken', data, { params: data });
  // noToken: true
}
//
// export function getInfo(token) {
//     return axios.post('/vue-admin-template/user/info', data, {params: data});
//
//     return request({
//         url: '/vue-admin-template/user/info',
//         method: 'get',
//         params: {token}
//     })
// }

export function logout() {
  return axios.delete('oauth/logout');
}
