import axios from 'axios';

// 获取用户配置字典
export function getDict() {
  return axios.get('/platform/component/dict/systemId/LC_PLATFORM'); // LC_PLATFORM为合规门户平台
}

// 获取用户主题
export function getUserTheme() {
  return axios.get('/platform/global/config?systemId=LC_PLATFORM'); // LC_PLATFORM为合规门户平台
}

// 获取用户子菜单列表数据
export function getMenuInfo(param) {
  return axios.get('/platform/home/user', { params: param });
}

// 获取子系统列表数据（systemid， path， systemname等）
export function getChildrenSystem() {
  return axios.get('/platform/systemInner/list?pageNum=1&pageSize=1000');
}

// 获取监管动态列表数据
export function getRegulatoryList(params) {
  return axios.post(`${process.env.VUE_APP_RT_SERVER_PREFIX}/rt/regulatoryTrends/pagingQuery`, params);
}

// 获取常用功能推荐
export function getSuggestList() {
  return axios.get('/platform/app/suggest');
}

// 下载图标
export function downLoadPicture(functionIcon) {
  return axios.get(`/platform/file/downLoadPicture/${functionIcon}`);
}

// 获取所有系统的功能
export function getSystemFunctionList() {
  return axios.get('/platform/app/search');
}

// 获取数据分析表格的标签
export function getAvailableDashboard() {
  return axios.get('/platform/dashboard/getAvailableDashboard');
}

// 获取所有数据分析表格的标签
export function getAllDashboard() {
  return axios.get('/platform/dashboard/getAllDashboard');
}

// 保存用户选择的数据分析
export function saveSelectedDashboard(body) {
  return axios.post('/platform/dashboard/saveSelectedDashboard', body);
}

// 获取仪表盘地址接口
export function getDashboardUrl(body) {
  return axios.post(`${process.env.VUE_APP_DM_SERVER_PREFIX}/dm/goTo`, body);
}

// 保存常用功能设置
export function saveSuggestFunction(body) {
  return axios.post('/platform/app/save', body);
}

// 记录页面功能菜单点击事件
export function logFunctionClick(body) {
  return axios.post('/platform/app/click/log', body);
}

/**
 * @description 获取所有子系统跳转路径
 * GET /platform/systemInner/detailInfo?systemType=MOBILE_WEB
 */
export function getSystemPath() {
  return axios.get('/platform/systemInner/detailInfo?systemType=MOBILE_WEB');
}
export function getSpeechStream(body) {
  return axios.post('/assistant/qa/tts', body);
}


/**
 * @description 获取userToken
 * GET /oauth/wechatlogin?code=${code}&state=${state}
 */
export function wechatLogin(code, state) {
  return axios.get(`/oauth/wechatlogin?code=${code}&state=${state}`);
}
