<template>
  <div class="app-item-wrapper" v-if="list.length">
    <div class="app-item-title">
      {{ title }}
    </div>
    <div class="app-item-list">
      <van-grid id="child" :column-num="5">
        <van-grid-item
          @click="intoSystemPage(item)"
          :data-id="item.id"
          v-for="(item, index) in list"
          :key="'function' + index"
          :text="item.resourceName"
        >
          <template v-slot:icon>
            <div class="app-image">
              <!--              <span v-html="item.functionIcon"></span>-->
              <img :src="'data:image/svg+xml;base64,' + item.functionIcon" />
              <slot
                v-if="isEdit"
                name="icon"
                :item="item"
                :index="index"
                :isSeleted="item.isSeleted"
              ></slot>
            </div>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppListItem',
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    title: '',
    list: [],
    // eslint-disable-next-line vue/require-prop-type-constructor
    icon: '',
    // eslint-disable-next-line vue/require-prop-type-constructor
    isEdit: false,
  },

  mounted() {},
  methods: {
    // 跳转到功能对应的子系统中
    intoSystemPage(system) {
      // 非编辑状态下才可跳转
      if (!this.isEdit) {
        this.$parent.toFunctionPage(system);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.van-grid-item {
  padding-right: 8px;

  ::v-deep {
    .van-grid-item__content--center {
      padding: 0;
    }

    .van-grid-item__icon-wrapper {
      width: 100%;
    }

    .van-grid-item__text {
      padding-bottom: 5px;
      font-size: 11px;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
    }
  }
}

.app-image {
  margin-top: 15px;
  position: relative;
  display: flex;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
  }
}

.app-item-wrapper {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
  padding: 12px 12px 5px 20px;
  margin: 10px 10px 0;
}

.app-item-title {
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  font-weight: bold;
}

.app-item-list {
}
</style>
