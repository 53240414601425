<template>
  <span
    class="svg-box"
    aria-hidden="true"
    v-on="$listeners"
    v-html="svgData"
  ></span>
</template>

<script>
import * as svgs from '../../constants/svg';

export default {
  name: 'CIcon',
  props: {
    name: null, // svg的名称
  },
  mounted() {
    const svg = this.$el.querySelector('svg');
    this.$el.setAttribute('style', `height: ${svg.getAttribute('height')}`);
  },
  computed: {
    svgData() {
      return svgs[this.name];
    },
  },
  methods: {},
};
</script>

<style scoped>
.svg-box {
}
</style>
