<template>
  <div id="app">
    <div
      class="c-router-view"
      :class="{ 'has-tabbar': $store.state.app.tabBars.opened }"
    >
      <router-view />
    </div>
    <van-tabbar
      v-model="$store.state.app.tabBars.active"
      active-color="var(--themeColor)"
      v-if="$store.state.app.tabBars.opened"
    >
      <van-tabbar-item to="/">
        <template #icon="props">
          <c-icon :name="props.active ? 'homeSelectedIcon' : 'homeIcon'" />
        </template>
        首页
      </van-tabbar-item>
      <van-tabbar-item to="/notice">
        <template #icon="props">
          <c-icon
            :name="props.active ? 'messageSelectedIcon' : 'messageIcon'"
          />
        </template>
        消息
      </van-tabbar-item>
      <van-tabbar-item to="/task">
        <template #icon="props">
          <c-icon :name="props.active ? 'taskSelectedIcon' : 'taskIcon'" />
        </template>
        任务
      </van-tabbar-item>
      <van-tabbar-item to="/favorite">
        <template #icon="props">
          <c-icon
            :name="props.active ? 'favoriteSelectedIcon' : 'favoriteIcon'"
          />
        </template>
        收藏
      </van-tabbar-item>
      <van-tabbar-item to="/qAnda">
        <template #icon="props">
          <c-icon
              :name="props.active ? 'aiSelectedIcon' : 'aiIcon'"
          />
        </template>
        晓合
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { initSystemInfoMixins } from './utils/mixins';

export default {
  mixins: [initSystemInfoMixins],
  created() {
    this.initSystem();
  },
  methods: {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 14px;
}

.van-tabbar {
  z-index: 100;
}

.has-tabbar {
  padding-bottom: 2.08rem;
}

.c-router-view {
  height: 100vh;
  background: #f9f9f9;
}
</style>
