var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home-page" },
    [
      _c(
        "div",
        { staticClass: "swipe-wrapper" },
        [
          _c("div", { staticClass: "search-dom-wrapper" }, [
            _c("div", {
              staticClass: "search-dom-bg",
              style: { opacity: _vm.searchOpacity },
            }),
            _c(
              "div",
              {
                staticClass: "search-dom",
                on: {
                  click: function ($event) {
                    return _vm.goToSearch()
                  },
                },
              },
              [
                _c("c-icon", { attrs: { name: "searchIcon" } }),
                _vm._v(" 请输入关键词搜索 "),
              ],
              1
            ),
          ]),
          _c(
            "van-swipe",
            {
              staticClass: "my-swipe",
              attrs: { autoplay: 8000, "indicator-color": "rgba(212, 60, 63)" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("van-swipe-item", [
                _c("img", {
                  attrs: { src: require("../../assets/banner/banner01.png") },
                }),
              ]),
              _c("van-swipe-item", [
                _c("img", {
                  attrs: { src: require("../../assets/banner/banner02.png") },
                }),
              ]),
              _c("van-swipe-item", [
                _c("img", {
                  attrs: { src: require("../../assets/banner/banner03.png") },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "app-list-wrapper",
          class: { "has-notice": !_vm.regulatoryList.length },
        },
        [
          _c(
            "van-grid",
            { attrs: { "column-num": 5 } },
            [
              _vm._l(_vm.suggestFunctionList, function (item, index) {
                return _c("van-grid-item", {
                  key: "suggestFunction" + index,
                  attrs: { text: item.resourceName },
                  on: {
                    click: function ($event) {
                      return _vm.goToFrame(item)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "function-icon" }, [
                              _c("img", {
                                attrs: {
                                  src:
                                    "data:image/svg+xml;base64," +
                                    item.functionIcon,
                                },
                              }),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("van-grid-item", {
                attrs: { text: "更多" },
                on: {
                  click: function ($event) {
                    return _vm.toEditPage()
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "function-icon" },
                          [
                            _c("c-icon", {
                              attrs: { name: "moreFunctionIcon" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            2
          ),
          _vm.regulatoryList.length
            ? _c("div", { staticClass: "notice-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "notice-title" },
                  [
                    _c("c-icon", { attrs: { name: "regulatoryIcon" } }),
                    _c("transition", { attrs: { name: "slide-fade" } }, [
                      _vm.regulatoryShow
                        ? _c(
                            "div",
                            {
                              staticClass: "notice-text van-ellipsis",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.goToSource(
                                    `/detail/${
                                      _vm.regulatoryList[
                                        _vm.regulatoryListCurrent
                                      ].regulatoryTrendsId
                                    }`
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.regulatoryTitle) + " ")]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.goToSource("")
                          },
                        },
                      },
                      [_vm._v("更多")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("van-sticky", { attrs: { "offset-top": 44 } }, [
        _vm.dashboardTabList.length
          ? _c(
              "div",
              { staticClass: "chart-tabs-wrapper" },
              [
                _c(
                  "van-tabs",
                  {
                    attrs: {
                      "swipe-threshold": 3,
                      swipeable: "",
                      "before-change": _vm.beforeTabsChange,
                    },
                    model: {
                      value: _vm.dashboardTabValue,
                      callback: function ($$v) {
                        _vm.dashboardTabValue = $$v
                      },
                      expression: "dashboardTabValue",
                    },
                  },
                  _vm._l(_vm.dashboardTabList, function (item, index) {
                    return _c(
                      "van-tab",
                      {
                        key: "dashboardTabList" + index,
                        attrs: {
                          name: item.dashboardId,
                          title: item.dashboardName,
                        },
                      },
                      [
                        _c("div", { staticClass: "chart-iframe" }, [
                          _c("iframe", {
                            attrs: {
                              src: _vm.dashboardUrl,
                              width: "100%",
                              height: "100%",
                              frameborder: "0",
                            },
                          }),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "change-tabs",
                    on: { click: _vm.changeEditChartShow },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("../../assets/more_tab.png") },
                    }),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          on: {
            close: function ($event) {
              return _vm.editChartClose()
            },
          },
          model: {
            value: _vm.editChartShow,
            callback: function ($$v) {
              _vm.editChartShow = $$v
            },
            expression: "editChartShow",
          },
        },
        [
          _vm.editChartShow
            ? _c("div", { staticClass: "my-data-source-wrapper" }, [
                _c("div", { staticClass: "data-source" }, [
                  _c("div", { staticClass: "data-source-title" }, [
                    _vm._v(" 我的数据分析内容 "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.isEditSource ? "长按拖动排序" : "点击进入看板"
                        )
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "data-source-edit",
                      on: {
                        click: function ($event) {
                          return _vm.editChartSource()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.isEditSource ? "完成" : "管理") + " "
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "chart-title-list" },
                  _vm._l(_vm.dashboardTabList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: "dashboardTabs" + index,
                        staticClass: "c-tag",
                        attrs: { "data-id": item.dashboardId },
                        on: {
                          click: function ($event) {
                            return _vm.intoChartTab(item)
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(item.dashboardName) + " "),
                        _vm.isEditSource
                          ? _c("van-icon", {
                              attrs: { name: "clear" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteChartTab(item, index)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "all-data-source-wrapper" }, [
            _c("div", { staticClass: "data-source" }, [
              _c("div", { staticClass: "data-source-title" }, [
                _vm._v(" 推荐数据分析内容 "),
                _c("span", [_vm._v("点击添加看板")]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "chart-title-list" },
              _vm._l(_vm.surplusDashboard, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "surplus" + index,
                    staticClass: "c-add-tag",
                    on: {
                      click: function ($event) {
                        return _vm.addChartTab(item, index)
                      },
                    },
                  },
                  [
                    _c("van-icon", { attrs: { name: "plus" } }),
                    _vm._v(" " + _vm._s(item.dashboardName) + " "),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }