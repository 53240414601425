import router from './router';
import store from './store';
import { getToken } from '@/utils/auth'; // get token from cookie
import getPageTitle from '@/utils/get-page-title';
import { getDict, getMenuInfo } from './api/index';
import { getUserId } from './utils/auth';

/*
数组转成Obj对象
* */
function getObj(dictList) {
  const obj = {};
  if (dictList == null || dictList.length === 0) {
    return obj;
  }
  dictList.forEach((ele) => {
    if (ele.dictStatus === 'VALID') {
      if (ele.children) {
        obj[ele.dictCode] = {};
        obj[ele.dictCode].name = ele.dictName;
        obj[ele.dictCode].children = getObj(ele.children);
      } else {
        obj[ele.dictCode] = ele.dictName;
      }
    }
  });
  return obj;
}

const whiteList = ['/login', '/redictLogin']; // no redirect whitelist
router.beforeEach(async (to, from, next) => {
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = getToken();

  // 维持tab bar的active和路由保持一致
  if (to.path.indexOf('notice') !== -1) {
    store.dispatch('app/setTabBar', 1);
  } else if (to.path.indexOf('task') !== -1) {
    store.dispatch('app/setTabBar', 2);
  } else if (to.path.indexOf('favorite') !== -1) {
    store.dispatch('app/setTabBar', 3);
  } else if (to.path.indexOf('qAnda') !== -1) {
    store.dispatch('app/setTabBar', 4);
  } else {
    store.dispatch('app/setTabBar', 0);
  }

  if (hasToken) {
    if (
      sessionStorage.getItem('dist_info') == null ||
      sessionStorage.getItem('user_info') == null
    ) {
      const dictGroupMap = {};
      await Promise.all([getDict(), getMenuInfo({ userId: getUserId() })]).then(
        (values) => {
          if (values[0].code === '0000000000') {
            values[0].result.dictGroupMap.forEach((ele) => {
              dictGroupMap[ele.key] = getObj(ele.value);
            });
            sessionStorage.setItem('dist_info', JSON.stringify(dictGroupMap));
          }
          if (values[1].code === '0000000000') {
            store.dispatch('app/setUserInfo', values[1].result.user);
            sessionStorage.setItem(
              'user_info',
              JSON.stringify(values[1].result.user),
            );
          }
        },
      );
    } else {
      store.dispatch(
        'app/setUserInfo',
        JSON.parse(sessionStorage.getItem('user_info')),
      );
    }

    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' });
    } else {
      const hasGetUserInfo = store.getters.name;
      if (hasGetUserInfo) {
        next();
      } else {
        try {
          // get user info
          await store.dispatch('user/getInfo');
          next();
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken');
          // next(`/login?redirect=${to.path}`);
          next({ path: '/redictLogin', query: to.query });
        }
      }
    }
  } else if (whiteList.indexOf(to.path) !== -1) {
    /* has no token */
    // in the free login whitelist`, go directly
    next();
  } else {
    next({ path: '/redictLogin', query: to.query });
  }
});

router.afterEach(() => {
  // finish progress bar
});
