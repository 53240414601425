<template>
  <div class="home-page">
    <div class="swipe-wrapper">
      <div class="search-dom-wrapper">
        <!--        空白背景，用来做滚动背景颜色变化-->
        <div class="search-dom-bg" :style="{ opacity: searchOpacity }"></div>
        <div class="search-dom" @click="goToSearch()">
          <c-icon name="searchIcon"></c-icon>
          请输入关键词搜索
        </div>
<!--        <c-icon class="login-out" name="loginOutIcon" @click="loginOut()" />-->
      </div>
      <van-swipe
        @click.stop=""
        class="my-swipe"
        :autoplay="8000"
        indicator-color="rgba(212, 60, 63)"
      >
        <van-swipe-item>
          <img src="../../assets/banner/banner01.png" />
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/banner/banner02.png" />
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/banner/banner03.png" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div
      class="app-list-wrapper"
      :class="{ 'has-notice': !regulatoryList.length }"
    >
      <van-grid :column-num="5">
        <van-grid-item
          v-for="(item, index) in suggestFunctionList"
          :key="'suggestFunction' + index"
          :text="item.resourceName"
          @click="goToFrame(item)"
        >
          <template v-slot:icon>
            <div class="function-icon">
              <img :src="'data:image/svg+xml;base64,' + item.functionIcon" />
            </div>
          </template>
        </van-grid-item>
        <van-grid-item @click="toEditPage()" text="更多">
          <template v-slot:icon>
            <div class="function-icon">
              <c-icon name="moreFunctionIcon"></c-icon>
            </div>
          </template>
        </van-grid-item>
      </van-grid>
      <div class="notice-wrapper" v-if="regulatoryList.length">
        <div class="notice-title">
          <c-icon name="regulatoryIcon"></c-icon>
          <transition name="slide-fade">
            <div
              class="notice-text van-ellipsis"
              @click.stop="
                goToSource(
                  `/detail/${regulatoryList[regulatoryListCurrent].regulatoryTrendsId}`
                )
              "
              v-if="regulatoryShow"
            >
              {{ regulatoryTitle }}
            </div>
          </transition>
          <span @click.stop="goToSource('')">更多</span>
        </div>
      </div>
    </div>

    <van-sticky :offset-top="44">
      <div class="chart-tabs-wrapper" v-if="dashboardTabList.length">
        <van-tabs
          v-model="dashboardTabValue"
          :swipe-threshold="3"
          swipeable
          :before-change="beforeTabsChange"
        >
          <van-tab
            :name="item.dashboardId"
            v-for="(item, index) in dashboardTabList"
            :key="'dashboardTabList' + index"
            :title="item.dashboardName"
          >
            <div class="chart-iframe">
              <iframe
                :src="dashboardUrl"
                width="100%"
                height="100%"
                frameborder="0"
              ></iframe>
            </div>
          </van-tab>
        </van-tabs>
        <div class="change-tabs" @click="changeEditChartShow">
          <img src="../../assets/more_tab.png" />
        </div>
      </div>
    </van-sticky>
    <!--    管理dashboard标签-->
    <van-popup v-model="editChartShow" position="top" @close="editChartClose()">
      <div v-if="editChartShow" class="my-data-source-wrapper">
        <div class="data-source">
          <div class="data-source-title">
            我的数据分析内容
            <span>{{ isEditSource ? "长按拖动排序" : "点击进入看板" }}</span>
          </div>
          <div class="data-source-edit" @click="editChartSource()">
            {{ isEditSource ? "完成" : "管理" }}
          </div>
        </div>
        <div class="chart-title-list">
          <div
            @click="intoChartTab(item)"
            class="c-tag"
            :data-id="item.dashboardId"
            v-for="(item, index) in dashboardTabList"
            :key="'dashboardTabs' + index"
          >
            {{ item.dashboardName }}
            <van-icon
              @click.stop="deleteChartTab(item, index)"
              v-if="isEditSource"
              name="clear"
            />
          </div>
        </div>
      </div>
      <div class="all-data-source-wrapper">
        <div class="data-source">
          <div class="data-source-title">
            推荐数据分析内容
            <span>点击添加看板</span>
          </div>
        </div>
        <div class="chart-title-list">
          <div
            class="c-add-tag"
            @click="addChartTab(item, index)"
            v-for="(item, index) in surplusDashboard"
            :key="'surplus' + index"
          >
            <van-icon name="plus" />
            {{ item.dashboardName }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Sortable from 'sortablejs';
import {
  getAllDashboard,
  getAvailableDashboard,
  getDashboardUrl,
  getRegulatoryList,
  saveSelectedDashboard,
} from '../../api';
import { getSuggestListMixins, toSystemPageMixins } from '../../utils/mixins';
import { Toast, Dialog } from 'vant';
import { dashboardFilterType } from '../../constants';
import { loginOut } from '../../utils/auth';

export default {
  mixins: [getSuggestListMixins, toSystemPageMixins],
  data() {
    return {
      regulatoryShow: true, // 是否展示监管动态列表项，用于动画
      editChartShow: false, // 是否展示表格tab编辑弹窗
      isEditSource: false, // 是否编辑表格排序、删除
      searchOpacity: 0, // 搜索框底色的透明度
      scrollListener: null, // 滚动监听器
      regulatoryList: [], // 监管动向列表数据
      dashboardTabList: [], // dashboard tab的数据列表
      dashboardTabValue: '', // dashboard tab当前选中tab的id
      surplusDashboard: [], // 剩余没有选中的dashboard tab的数据列表
      regulatoryListCurrent: 0, // 当前滚动的监管动向
      dashboardUrl: '', // 当前dashboard地址
      sortable: null,
    };
  },

  created() {
    this.getRegulatoryList();
    this.getAvailableDashboard();
  },
  computed: {
    regulatoryTitle() {
      return this.regulatoryList.length
        ? this.regulatoryList[this.regulatoryListCurrent].title
        : '';
    },
  },
  mounted() {
    this.scrollListener = window.addEventListener('scroll', () => {
      this.searchOpacity = window.scrollY / 200;
    });
    this.regulatoryAnimation();
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollListener);
  },
  methods: {
    // 跳转到监管动态详情,更多
    goToSource(params) {
      this.toSystemFrame(this.getSystemPath('LC_REGULATORY_TRENDS'), params);
    },
    changeIsSort(bool) {
      this.isEditSource = bool;
      const sortOptions = {
        group: 'name', // or { name: "...", pull: [true, false, 'clone', array], put: [true, false, array] }
        sort: true, // boolean 定义是否列表单元是否可以在列表容器内进行拖拽排序
        delay: 0, // number 定义鼠标选中列表单元可以开始拖动的延迟时间；
        disabled: !bool,
        animation: 300,
      };
      this.sortable = new Sortable(
        document.querySelector('.chart-title-list'),
        sortOptions,
      );
    },

    // 监管动态的自动滚动
    regulatoryAnimation() {
      setInterval(() => {
        this.regulatoryShow = false;
        setTimeout(() => {
          this.regulatoryListCurrent++;
          if (this.regulatoryListCurrent === this.regulatoryList.length) {
            this.regulatoryListCurrent = 0;
          }
          this.regulatoryShow = true;
        }, 1000);
      }, 10000);
    },
    // 获取监管动向的数据列表
    getRegulatoryList() {
      getRegulatoryList({ pageNum: 1, pageSize: 4 }).then((res) => {
        if (res.code === '0000000000') {
          this.regulatoryList = res.result;
        }
      });
    },
    // 获取dashboard tab的数据列表
    getAvailableDashboard() {
      getAvailableDashboard().then((res) => {
        if (res.code === '0000000000') {
          this.dashboardTabList = res.result;
          this.beforeTabsChange(
            this.dashboardTabList[0] && this.dashboardTabList[0].dashboardId,
          );
        }
        getAllDashboard().then((resp) => {
          if (resp.code === '0000000000') {
            this.surplusDashboard = resp.result.filter((item) => {
              return !this.dashboardTabList.some(
                (dashboard) => item.dashboardId === dashboard.dashboardId,
              );
            });
          }
        });
      });
    },
    // 仪表盘tab change前hook
    beforeTabsChange(name) {
      if (!name) {
        return;
      }
      const tab = this.dashboardTabList.find(
        (item) => item.dashboardId === name,
      );
      if (tab) {
        this.getDashboardUrl(tab.dashboardResourceName);
      }
      return true;
    },
    // 获取仪表盘地址, name为仪表盘配置名称
    getDashboardUrl(name) {
      const params = { name };
      if (dashboardFilterType[name]) {
        params.filters = [
          {
            filterName: dashboardFilterType[name],
            filterValue: [
              this.$store.state.app.userInfo.pathName.split('/')[1],
            ],
          },
        ];
      }
      getDashboardUrl(params).then((res) => {
        if (res.code === '0000000000') {
          this.dashboardUrl = res.result;
        }
      });
    },
    // 保存用户选择的数据分析
    saveSelectedDashboard(dashboardIds) {
      Toast.loading({
        message: '保存中...',
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
      saveSelectedDashboard(dashboardIds).then((res) => {
        Toast.clear();
        if (res.result) {
          Toast.success('保存成功');
          this.getAvailableDashboard();
          this.editChartClose();
        } else {
          Toast.fail('保存失败');
        }
      });
    },
    // 前往子系统
    goToFrame(system) {
      this.toFunctionPage(system);
    },
    // 前往搜索页面
    goToSearch() {
      this.$router.push('/search');
    },
    loginOut() {
      Dialog.confirm({
        title: '确认是否退出当前用户？',
      })
        .then(() => {
          // on confirm
          loginOut();
          this.$router.push('/login');
        })
        .catch(() => {
          // on cancel
        });
    },
    // 进入到功能列表的的编辑状态
    toEditPage() {
      this.$router.push('/app-list');
    },
    // 显示编辑图表tab的弹窗
    changeEditChartShow() {
      this.editChartShow = true;
      this.searchOpacity = 1;
    },
    // 关闭编辑图表tab的弹窗事件
    editChartClose() {
      this.changeIsSort(false);
      this.editChartShow = false;
      setTimeout(() => {
        this.searchOpacity = window.scrollY / 200;
      }, 300);
    },
    // 开启编辑图表tab
    editChartSource() {
      this.changeIsSort(!this.isEditSource);
      if (!this.isEditSource) {
        this.saveSelectedDashboard(
          this.sortable.toArray().map((item) => Number(item)),
        );
      }
    },
    // 从首页图表tabs中删除单个tab
    deleteChartTab(item, index) {
      if (this.dashboardTabList.length < 2) {
        Toast('数据分析内容不能再少了哦');
        return;
      }
      this.dashboardTabList.splice(index, 1);
      this.surplusDashboard.push(item);
    },
    // 添加图表tab到首页tabs中
    addChartTab(item, index) {
      this.surplusDashboard.splice(index, 1);
      this.dashboardTabList.push(item);
      if (!this.isEditSource) {
        this.intoChartTab(item);
      }
    },
    // 点击图表tab，进入到该tab
    intoChartTab(item) {
      this.editChartShow = false;
      setTimeout(() => {
        this.dashboardTabValue = item.dashboardId;
        this.beforeTabsChange(this.dashboardTabValue);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.swipe-wrapper {
  position: relative;
  height: 190px;
  border-radius: 0 0 8px 8px;
  overflow: hidden;

  .van-swipe {
    height: 100%;
  }
}

.my-swipe {
  img {
    width: 100%;
    height: 100%;
  }
}

.my-swipe ::v-deep {
  .van-swipe__indicators {
    bottom: 40px;
    right: 36px;
    left: unset;
    transform: unset;
  }

  .van-swipe__indicator {
    background: var(--themeBackgroundColor);
  }

  .van-swipe__indicator--active {
    background-color: var(--themeColor) !important;
    width: 10px;
    border-radius: 6px;
  }
}

.van-popup--top {
  top: 44px;
  height: 272px;
  background: #ffffff;
  border-bottom: 0.5px solid #e5e5e5;
  border-radius: 0 0 20px 20px;
  padding: 15px;
}

.search-dom-wrapper {
  position: fixed;
  padding: 4px 15px 9px;
  width: 100%;
  z-index: 9999;
  top: 0;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  .search-dom-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    background: #f1f1f1;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .login-out {
    margin-left: 8px;
  }
}

.search-dom {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;

  > span {
    margin-right: 4px;
    margin-top: -1px;
  }
}

.app-list-wrapper {
  background: #ffffff;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 8px;
  margin: 10px 15px 30px;
  top: -36px;
  padding: 10px 0;

  &.has-notice {
    margin-bottom: -24px;
  }
}

.van-grid-item {
  border-radius: 8px;
  overflow: hidden;
}

.function-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: transparent;

  img {
    text-align: center;
    width: 24px;
  }
}

.notice-wrapper {
  margin: 10px 15px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  padding: 8px;
  position: absolute;
  bottom: -60px;
  left: -15px;
  width: 100%;
}

.notice-text {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  margin: 0 10px;
  flex: 1;
}

.notice-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #000000;
  line-height: 20px;

  img {
    width: 67px;
  }

  span:last-child {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }
}

.chart-tabs-wrapper {
  ::v-deep {
    .van-tabs--line .van-tabs__wrap {
      padding-right: 40px;
    }

    .van-tabs__content {
      margin-top: -42px;
    }
  }

  position: relative;

  .change-tabs {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 1.173333rem;
    background: #fff;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 20px;

    img {
      height: 16px;
      padding-left: 6px;
      border-left: 2px solid #e8e8e8;
      border-bottom-color: transparent;
      border-top-color: transparent;
    }
  }
}

.chart-iframe {
  height: calc(100vh - 45px);
  padding-bottom: 80px;
}

.all-data-source-wrapper {
  margin-top: 15px;

  .c-tag .van-icon {
    color: #52c41a;
  }
}

.data-source {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data-source-edit {
  font-weight: 400;
  color: var(--themeColor);
}

.data-source-title {
  font-weight: bold;
  color: #333333;

  span {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 18px;
    margin-left: 10px;
  }
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateY(5px);
  opacity: 0;
}
</style>
