const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  messageUpdateEvent: (state) => state.message.messageUpdateEvent,
  todoUpdateEvent: (state) => state.message.todoUpdateEvent,
};
export default getters;
