var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    _vm._g(
      {
        staticClass: "svg-box",
        attrs: { "aria-hidden": "true" },
        domProps: { innerHTML: _vm._s(_vm.svgData) },
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }