// 控制页面下方的tab显示
/* eslint-disable no-param-reassign */
const states = {
  tabBars: {
    opened: true,
    active: 0,
  },
  device: 'desktop',
  childrenSystem: [], // 所有推荐子系统列表
  user: {}, // 所有推荐子系统列表，
  instAnswerMsg: [],
};

const mutations = {
  SHOW_SIDEBAR: (state) => {
    state.tabBars.opened = true;
  },
  CLOSE_SIDEBAR: (state) => {
    state.tabBars.opened = false;
  },
  SET_SIDEBAR: (state, active) => {
    state.tabBars.active = active;
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device;
  },
  SET_CHILDREN_SYSTEM: (state, childrenSystem) => {
    state.childrenSystem = childrenSystem;
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_QA: (state, message) => {
    state.instAnswerMsg.push(message);
  },
};

const actions = {
  showSideBar({ commit }) {
    commit('SHOW_SIDEBAR');
  },
  closeSideBar({ commit }) {
    commit('CLOSE_SIDEBAR');
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device);
  },
  setChildrenSystem({ commit }, childrenSystem) {
    commit('SET_CHILDREN_SYSTEM', childrenSystem);
  },
  setUserInfo({ commit }, userInfo) {
    commit('SET_USER_INFO', userInfo);
  },
  setTabBar({ commit }, active) {
    commit('SET_SIDEBAR', active);
  },
  setQa({ commit }, active) {
    commit('SET_QA', active);
  },
};

export default {
  namespaced: true,
  state: states,
  mutations,
  actions,
};
