/* eslint-disable no-param-reassign */
const states = {
  visitedViews: [],
  cachedViews: [],
};
const mutations = {
  DEL_VISITED_VIEW: (state, view) => {
    if (state.cachedViews.includes(view.name)) return;
    if (!view.meta.noCache) {
      state.cachedViews.push(view.name);
    }

    // 设置嵌入iframe
    if (state.visitedViews.some((v) => v.path === view.fullPath)) return;
    state.visitedViews.push({
      name: view.name,
      path: view.fullPath,
      title: view.meta.title || 'no-name',
    });
  },
  DEL_ALL_CACHED_VIEWS: (state) => {
    state.cachedViews = [];
  },
};
const actions = {
  delAllViews({ dispatch, state }, view) {
    return new Promise((resolve) => {
      dispatch('delAllVisitedViews', view);
      dispatch('delAllCachedViews', view);
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews],
      });
    });
  },
  delVisitedView({ commit, state }, view) {
    return new Promise((resolve) => {
      commit('DEL_VISITED_VIEW', view);
      resolve([...state.visitedViews]);
    });
  },
  delAllCachedViews({ commit, state }) {
    return new Promise((resolve) => {
      commit('DEL_ALL_CACHED_VIEWS');
      resolve([...state.cachedViews]);
    });
  },
};

export default {
  namespaced: true,
  state: states,
  mutations,
  actions,
};
