/* eslint-disable */
import { login, logout, refreshToken } from '@/api/user';
import {
  getToken,
  setToken,
  removeToken,
  setUserId,
  setIat,
  setExp,
  removeUserId,
  getRefreshToken,
  setRefreshToken,
  removeRefreshToken,
} from '@/utils/auth';
import { Toast } from 'vant';
import CryptoJS from '/public/crypto-js';

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    refreshToken: getRefreshToken(),
  };
};

const states = getDefaultState();
const encryptByDES = (message) => {
  const key = 'kEHrDooxWHKWCWtfeSxvDvgqZqa';
  /* global CryptoJS */
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_REFRESH_TOKEN: (state, token) => {
    state.refreshToken = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { userId, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({
        userId: encryptByDES(userId.trim()),
        password: encryptByDES(password),
      })
        .then((response) => {
          if (response.code === '0000000000') {
            commit('SET_TOKEN', response.result.jwt_token);
            setToken(response.result.jwt_token);
            commit('SET_NAME', userId);
            // 存储登录时间和过期时间
            setIat(new Date().getTime().toString());
            const exp = new Date()
              .setSeconds(response.result.accessTokenValidity)
              .toString();
            setExp(exp);
            //  存储刷新token
            setRefreshToken(response.result.refresh_token);
            commit('SET_REFRESH_TOKEN', response.result.refresh_token);
            setUserId(userId); // 添加userid
            resolve();
          } else {
            Toast.fail(response.message);
            reject(response);
          }
          // commit('SET_TOKEN', data.token)
          // setToken(data.token)
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  shrcbLogin({ commit }, response) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', response.result.jwt_token);
      setToken(response.result.jwt_token);
      commit('SET_NAME', response.result.userId.trim());
      // 存储登录时间和过期时间
      setIat(new Date().getTime().toString());
      //  存储刷新token
      setRefreshToken(response.result.refresh_token);
      commit('SET_REFRESH_TOKEN', response.result.refresh_token);
      setUserId(response.result.userId.trim()); // 添加userid
      resolve();
    });
  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve) => {
      // getInfo(state.token).then(response => {
      // const { data } = response
      // if (!data) {
      //   return reject('Verification failed, please Login again.')
      // }
      // const { name, avatar } = data
      commit('SET_NAME', state.name);
      resolve();
      // commit('SET_AVATAR', avatar)
      // resolve(data)
      // }).catch(error => {
      //   reject(error)
      // })
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          removeToken(); // must remove  token  first
          removeRefreshToken();
          commit('RESET_STATE');
          resolve();
          removeUserId();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshToken({ commit, state }) {
    return new Promise((resolve, reject) => {
      refreshToken({ refreshToken: state.refreshToken })
        .then((response) => {
          if (response.code === '0000000000') {
            commit('SET_TOKEN', response.result.jwt_token);
            setToken(response.result.jwt_token);
            setRefreshToken(response.result.refresh_token);
            setIat(new Date().getTime().toString());
            const exp = new Date().setSeconds(response.result.accessTokenValidity).toString();
            setExp(exp);
            commit('SET_REFRESH_TOKEN', response.result.refresh_token);
          } else {
            reject(response);
          }
          // commit('SET_TOKEN', data.token)
          // setToken(data.token)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logoutClear() {
    removeToken(); // must remove  token  first
    removeRefreshToken();
    removeUserId();
  },
  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken(); // must remove  token  first
      removeUserId();
      commit('RESET_STATE');
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state: states,
  mutations,
  actions,
};
