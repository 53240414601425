<template>
  <div class="empty-wrapper">
    <img src="../../assets/empty.png" />
    <div class="empty-desc">{{ desc }}</div>
  </div>
</template>

<script>
export default {
  name: 'EmptyList',
  props: ['desc'],
};
</script>

<style scoped>
.empty-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
img {
  width: 180px;
  margin: 0 auto;
}
.empty-desc {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  text-align: center;
}
</style>
