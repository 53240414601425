<template>
  <div>
    <van-nav-bar
      title="标题"
      left-text="返回"
      right-text="按钮"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
  </div>
</template>

<script>
// 公用头部组件，同样使用于子系统中，通过message事件通信
export default {
  data() {
    return {};
  },
  methods: {
    onClickLeft() {},
    onClickRight() {},
  },
};
</script>

<style scoped></style>
