var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.list.length
    ? _c("div", { staticClass: "app-item-wrapper" }, [
        _c("div", { staticClass: "app-item-title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _c(
          "div",
          { staticClass: "app-item-list" },
          [
            _c(
              "van-grid",
              { attrs: { id: "child", "column-num": 5 } },
              _vm._l(_vm.list, function (item, index) {
                return _c("van-grid-item", {
                  key: "function" + index,
                  attrs: { "data-id": item.id, text: item.resourceName },
                  on: {
                    click: function ($event) {
                      return _vm.intoSystemPage(item)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "app-image" },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      "data:image/svg+xml;base64," +
                                      item.functionIcon,
                                  },
                                }),
                                _vm.isEdit
                                  ? _vm._t("icon", null, {
                                      item: item,
                                      index: index,
                                      isSeleted: item.isSeleted,
                                    })
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }