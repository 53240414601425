import axios from 'axios';

/**
 * @returns 消息待办接口
 * @param data
 */

// 获取消息
export function getMessageUser(data) {
  return axios.post('/platform/message/user', data);
}

// 通知按照各子系统进行汇总，形成一级目录
export function getSystemUnreadMessage() {
  return axios.get('/platform/message/system');
}

// 根据系统id删除消息
export function deleteSystemMessage(systemId) {
  return axios.delete('/platform/message/systemId', { params: { systemId } });
}

// 根据消息id删除消息
export function deleteMessage(data) {
  return axios.delete('/platform/message/delete', { data });
}

// 已读消息
export function haveReadMessage(messageId) {
  return axios.get(`/platform/message/haveRead/${messageId}`);
}

// 刷新消息
export function refreshMessage(params) {
  return axios.get('/platform/message/refresh', { params });
}
export function getVirtualGroupAccessReal(params) {
  return axios.get('/platform/message/refresh', { params });
}
