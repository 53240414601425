import axios from 'axios';

/**
 * @returns 搜索相关
 * @param data
 */

// 获取搜索历史
export function getSearchHistory(data) {
  return axios.get('/platform/superSearch/history', { params: data });
}

// 获取联想结果
export function saveHistory(data) {
  return axios.post('/platform/superSearch/saveHistory', data);
}

// 获取联想结果
export function getSearchSuggest(data) {
  return axios.post('/platform/superSearch/suggest', data);
}

// 单个删除历史记录
export function deleteHistory(keyWord) {
  return axios.delete('/platform/superSearch/deleteHistory', {
    data: { keyWord },
  });
}

// 清空历史记录
export function cleanAllHistory() {
  return axios.delete('/platform/superSearch/cleanHistory');
}

// 搜索
export function searchCategory(body) {
  // if (body.systemId === 'LC_IE') {
  return axios.post('/platform/superSearch/searchCategory', body);
  // } else {
  //     return axios.post('/platform/superSearch/search', body)
  // }
}

export function selectSystemBySearchKey(params) {
  return axios.post('/platform/superSearch/selectSystemBySearchKey', params);
}
