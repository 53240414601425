var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-nav-bar", {
        attrs: {
          title: "标题",
          "left-text": "返回",
          "right-text": "按钮",
          "left-arrow": "",
        },
        on: { "click-left": _vm.onClickLeft, "click-right": _vm.onClickRight },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }