<!--eslint-disable no-param-reassign -->
<template>
  <div class="app-list-page">
    <van-nav-bar
      title="应用中心"
      :right-text="isEdit ? '保存' : '管理'"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="app-list-wrapper">
      <app-list-item
        :isEdit="isEdit"
        title="常用功能"
        :list="suggestFunctionList"
      >
        <template v-slot:icon="ability">
          <span @click.stop="deleteFunction(ability)" class="cut-icon"
            ><span></span
          ></span>
        </template>
      </app-list-item>
      <app-list-item
        :isEdit="isEdit"
        :title="item.resourceName"
        :list="item.children"
        v-for="(item, index) in allSystemFunctionList"
        :key="'allSystemFunctionList' + index"
      >
        <template v-slot:icon="ability">
          <van-icon
            v-if="!ability.isSeleted"
            @click.stop="addFunction(ability, item)"
            class="add-icon"
            name="add"
          />
        </template>
      </app-list-item>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import AppListItem from '../../components/home/app-list-item';
import {
  closeTabBarsMixins,
  getSuggestListMixins,
  toSystemPageMixins,
} from '@/utils/mixins';
import {
  getSystemFunctionList,
  saveSuggestFunction,
  downLoadPicture,
} from '@/api';
import Sortable from 'sortablejs';

export default {
  name: 'AppList',
  mixins: [closeTabBarsMixins, getSuggestListMixins, toSystemPageMixins],
  data() {
    return {
      isEdit: false,
      allSystemFunctionList: [],
      sortable: null,
    };
  },
  components: {
    AppListItem,
  },
  created() {
    this.getSystemFunctionList();
  },
  watch: {
    // 如果 `isEdit` 发生改变，这个函数就会运行
    isEdit(newValue, oldValue) {
      const sortOptions = {
        group: 'name', // or { name: "...", pull: [true, false, 'clone', array], put: [true, false, array] }
        sort: true, // boolean 定义是否列表单元是否可以在列表容器内进行拖拽排序
        delay: 0, // number 定义鼠标选中列表单元可以开始拖动的延迟时间；
        disabled: oldValue,
        animation: 300,
      };
      this.sortable = new Sortable(
        document.querySelector('#child'),
        sortOptions,
      );
    },
  },
  methods: {
    getSystemFunctionList() {
      getSystemFunctionList().then((res) => {
        if (res.code === '0000000000') {
          this.allSystemFunctionList = res.result;
          this.allSystemFunctionList.forEach((ele) => {
            const promisesArr = [];
            ele.children.forEach((child) => {
              const { functionIcon } = child;
              child.functionIcon = null;
              promisesArr.push(downLoadPicture(functionIcon));
            });
            Promise.all(promisesArr)
              .then((results) => {
                results.forEach((data, index) => {
                  if (data.code === '0000000000') {
                    /* eslint-disable no-param-reassign */
                    ele.children[index].functionIcon = data.result;
                  }
                });
              });
          });
          this.operateAllSystemFunction();
        }
      });
    },
    onClickLeft() {
      this.$router.back();
    },
    // 点击开始编辑功能，保存
    onClickRight() {
      if (this.isEdit) {
        Toast.loading({
          message: '保存中...',
          forbidClick: true,
          duration: 0, // 持续展示 toast
        });
        const arr = this.sortable.toArray();
        const body = arr.map((item) => {
          const func = this.suggestFunctionList.find(
            (fun) => fun.id === Number(item),
          );
          return {
            id: func.id,
          };
        });
        saveSuggestFunction(body).then((res) => {
          Toast.clear();
          if (res.code === '0000000000') {
            Toast.success('保存成功');
          } else {
            Toast.fail('保存失败');
          }
        });
      }
      this.isEdit = !this.isEdit;
    },
    // 添加到推荐功能
    addFunction(ability) {
      if (this.suggestFunctionList.length >= 9) {
        Toast('最多选择九个常用功能');
        return;
      }
      /* eslint-disable no-param-reassign */
      ability.item.isSeleted = true;
      this.suggestFunctionList.push(ability.item);
    },
    // 删除推荐功能
    deleteFunction(ability) {
      if (this.suggestFunctionList.length < 2) {
        Toast('至少保留一个常用功能');
        return;
      }
      this.suggestFunctionList.splice(ability.index, 1);
      this.allSystemFunctionList.forEach((item) => {
        /* eslint-disable no-param-reassign */
        item.children.forEach((child) => {
          if (child.id === ability.item.id) {
            /* eslint-disable no-param-reassign */
            child.isSeleted = false;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.van-nav-bar ::v-deep {
  .van-nav-bar__text {
    color: var(--themeColor);
    font-size: 14px;
    font-weight: 400;
  }
}

.app-list-wrapper {
  height: calc(100vh - 48px);
  overflow-y: scroll;
  padding-bottom: 20px;
}

.add-icon,
.cut-icon {
  position: absolute;
  top: -5px;
  right: 10px;
  font-size: 14px;
}

.add-icon {
  color: var(--themeColor);
}

.cut-icon {
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d8d8d8;
  border-radius: 50%;
  color: #fff;
  margin: 1px;

  span {
    display: block;
    height: 1px;
    width: 6px;
    background: #fff;
  }
}
</style>
