/* eslint-disable no-param-reassign */
/* eslint-disable import/newline-after-import */
import defaultSettings from '@/settings';

const {
  showSettings,
  fixedHeader,
  sidebarLogo,
  layoutColor,
  roleType,
  userinfo,
  roleTypeArr,
  menuArr,
} = defaultSettings;

const states = {
  showSettings,
  fixedHeader,
  sidebarLogo,
  layoutColor,
  roleType,
  userinfo,
  roleTypeArr,
  menuArr,
};

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value;
    }
  },
};

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data);
  },
};

export default {
  namespaced: true,
  state: states,
  mutations,
  actions,
};
