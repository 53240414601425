import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import axios from 'axios';
import './permission'; // permission control
import 'lib-flexible';
import { vant } from './config/vant.config.js';
import './styles/main.scss';
import _ from 'lodash';
import './components/index';

import { interceptors } from './utils/interceptors';
import './utils/filter';

// import Vconsole from 'vconsole'
//
// new VConsole();
// let vConsole = new Vconsole()
// Vue.use(vConsole)

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === 'production') {
  const { mockXHR } = require('../mock');
  mockXHR();
}
interceptors(axios, store, router, {
  refreshToken: 'user/refreshToken',
  filtersUrl: ['oauth'],
});

Vue.prototype.$_ = _;
Vue.use(vant);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
