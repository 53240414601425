module.exports = {
  title: '方圆合规',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false,
  /**
   * 设置布局颜色
   */
  layoutColor: '白色',

  roleType: '超级管理员',
  userinfo: null,
  roleTypeArr: [],
  menuArr: [],
};
