/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import {
  getSuggestList,
  downLoadPicture,
  logFunctionClick,
  getUserTheme,
  getChildrenSystem } from '../api/index';
import { Toast } from 'vant';
import CryptoJS from '/public/crypto-js';
import {
  getToken,
  setTheme,
} from './auth';

const ReRegExp = require('reregexp').default;
// 用于页面关闭tab的周期事件
export const closeTabBarsMixins = {
  mounted() {
    this.$store.dispatch('app/closeSideBar');
  },
  destroyed() {
    this.$store.dispatch('app/showSideBar');
  },
};

// 用于获取用户所有菜单权限得数据（path，systemId等）
export const initSystemInfoMixins = {
  methods: {
    $_newHeader() {
      const time = new Date();
      const r1 = new ReRegExp(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-4[a-fA-F0-9]{3}-y[a-fA-F0-9]{3}-[a-fA-F0-9]{12}/);
      const nonce = r1.build();
      const requestTime = time.getTime() + (time.getTimezoneOffset() * 60000); // 设置时区
      const signstring = `timestamp=${requestTime}&nonce=${nonce}`;
      const hash = CryptoJS.MD5(signstring).toString(CryptoJS.enc.Hex);
      return {
        Requesttime: requestTime,
        Nonce: nonce,
        Sign: hash,
      };
    },
    initSystem() {
      // 在未登录情况下需要调用下接口使得系统自动跳转到login页面
      this.getUserTheme();
      if (getToken()) {
        this.getMenuInfo();
      }
    },
    getUserTheme() {
      // 请求字典数据，获取用户主题
      getUserTheme().then((res) => {
        if (res.code === '0000000000') {
          res.result.forEach((item) => {
            if (item.bindDictCode === 'THEME' && item.value) {
              const body = document.querySelector('body');
              if (item.value.configKey === 'blue') {
                body.className = 'blue-theme';
              }
              setTheme(item.value.configKey);
            }
          });
        }
      });
    },
    getMenuInfo() {
      // 获取所有子系统的数据（对应系统id，系统url等）
      const systemBaseUrl = {};
      getChildrenSystem().then((res) => {
        if (res.code === '0000000000') {
          res.result.records &&
            res.result.records.forEach((item) => {
              item.systemCategory.forEach((category) => {
                if (category.systemType === 'MOBILE_WEB') {
                  item.path = category.baseUrl;
                  systemBaseUrl[item.systemId] = category.baseUrl;
                }
              });
            });
          this.$store.dispatch('app/setChildrenSystem', res.result.records);
        }
      });
    },
  },
};

// 用于直接跳转子系统页面
export const toSystemPageMixins = {
  methods: {
    toSystemFrame(baseUrl, param = '') {
      if (!baseUrl) {
        Toast.fail('跳转失败，路径为空~');
        return;
      }
      const path = baseUrl + param;
      sessionStorage.setItem('childrenPath', path);
      // sessionStorage.setItem('previousPath', this.$route.fullPath); // 保存进入时的路径
      this.$router.push({ path: '/childsystem' });
    },
    getSystemPath(systemId) {
      let path = null;
      this.$store.state.app.childrenSystem.forEach((item) => {
        if (systemId === item.systemId) {
          path = item.path;
        }
      });
      return path;
    },
  },
};

// 用于推荐功能列表的查询
export const getSuggestListMixins = {
  data() {
    return {
      suggestFunctionList: [], // 所有推荐子系统列表
    };
  },
  created() {
    this.getSuggestFunction();
  },
  methods: {
    // 处理base64 =》 转成svg用于图片展示
    // mapList(list) {
    //     list.forEach(item => {
    //         item.functionIcon = item.functionIcon ? atob(item.functionIcon) : '';
    //     })
    // },
    getSuggestFunction() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 3, // 持续展示 toast
        overlay: true,
      });
      getSuggestList()
        .then((res) => {
          if (res.code === '0000000000') {
            this.suggestFunctionList = res.result;
            this.suggestFunctionList.forEach((ele) => {
              const { functionIcon } = ele;
              ele.functionIcon = null;
              downLoadPicture(functionIcon).then((data) => {
                if (data.code === '0000000000') {
                  ele.functionIcon = data.result;
                }
              });
            });
            this.operateAllSystemFunction();
            Toast.clear();
          } else {
            Toast.fail('常用功能加载失败');
          }
        })
        .catch(() => {
          Toast.clear();
        });
    },

    // 处理应用卡片中的功能，将已经是常用功能的功能设置isSelected为true
    operateAllSystemFunction() {
      if (
        this.allSystemFunctionList?.length &&
        this.suggestFunctionList?.length
      ) {
        this.allSystemFunctionList.forEach((system) => {
          // 将已经是常用功能的功能过滤
          system.children.forEach((item) => {
            item.isSeleted = this.suggestFunctionList.find(
              (suggest) => suggest.id === item.id,
            );
          });
        });
      }
    },

    // 点击跳转及功能埋点
    toFunctionPage(fun) {
      const { path, id } = fun;
      this.toSystemFrame(path);
      // 埋点
      logFunctionClick({
        resourceId: id,
        url: path,
        urlRef: location.href,
      }).then(() => {});
    },
  },
};
