import {
  Button,
  List,
  Cell,
  CellGroup,
  NavBar,
  Icon,
  Search,
  Tab,
  Tabs,
  Field,
  Picker,
  ActionSheet,
  Popover,
  Empty,
  Divider,
  Overlay,
  Popup,
  RadioGroup,
  Radio,
  Sticky,
  Notify,
  Checkbox,
  CheckboxGroup,
  DatetimePicker,
  Loading,
  Dialog,
  Form,
  Tabbar,
  TabbarItem,
  Grid,
  GridItem,
  SwipeItem,
  Swipe,
  SwipeCell,
  Badge,
  DropdownMenu,
  DropdownItem,
  Calendar,
  Skeleton,
} from 'vant';

export function vant(app) {
  app
    .use(Button)
    .use(List)
    .use(Cell)
    .use(CellGroup)
    .use(NavBar)
    .use(Icon)
    .use(Search)
    .use(Tab)
    .use(Tabs)
    .use(Field)
    .use(Picker)
    .use(ActionSheet)
    .use(DatetimePicker)
    .use(Empty)
    .use(Divider)
    .use(Overlay)
    .use(Popup)
    .use(RadioGroup)
    .use(Radio)
    .use(Sticky)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Notify)
    .use(Form)
    .use(Loading)
    .use(Popover)
    .use(Tabbar)
    .use(TabbarItem)
    .use(Grid)
    .use(GridItem)
    .use(Dialog)
    .use(Swipe)
    .use(SwipeItem)
    .use(SwipeCell)
    .use(Badge)
    .use(DropdownMenu)
    .use(Skeleton)
    .use(DropdownItem)
    .use(Calendar);
}
