/**
 * 全局vue过滤器
 */
import Vue from 'vue';
import { formatTime } from './index';

// 时间格式化过滤器
Vue.filter('formatDate', (value, option) => {
  if (!value) return '';
  const date = new Date(value).getTime();
  return formatTime(date, option);
});
