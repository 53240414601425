var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-page" },
    [
      _c("van-nav-bar", {
        attrs: {
          "right-text": _vm.isShowRes ? "取消" : "搜索",
          "left-arrow": "",
        },
        on: { "click-left": _vm.onClickLeft, "click-right": _vm.onClickRight },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "form",
                  { attrs: { action: "javascript: return true" } },
                  [
                    _c("van-search", {
                      attrs: {
                        shape: "round",
                        clearable: false,
                        placeholder: "请输入关键词搜索",
                        autoFocus: "",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.getSearchSuggest()
                        },
                        search: function ($event) {
                          return _vm.clickSearchBtn()
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "left-icon",
                          fn: function () {
                            return [
                              _c("c-icon", {
                                staticClass: "search-icon",
                                attrs: { name: "searchIcon" },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "right-icon",
                          fn: function () {
                            return [
                              _vm.searchValue !== ""
                                ? _c("c-icon", {
                                    staticClass: "search-icon",
                                    attrs: { name: "clearIcon" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearSearchValue()
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.searchValue,
                        callback: function ($$v) {
                          _vm.searchValue =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "searchValue",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      !_vm.isShowRes && !_vm.searchValue.length
        ? _c("div", { staticClass: "history-list-wrapper" }, [
            _vm.history.length
              ? _c("div", { staticClass: "history-title" }, [
                  _vm._v(" 历史搜索 "),
                  _c(
                    "div",
                    { staticClass: "delete-text" },
                    [
                      _vm.isDeleteStatus
                        ? _c(
                            "span",
                            {
                              staticClass: "clear-all",
                              on: {
                                click: function ($event) {
                                  return _vm.confirmDelete()
                                },
                              },
                            },
                            [_vm._v("清空全部")]
                          )
                        : _vm._e(),
                      _vm.isDeleteStatus
                        ? _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeDeleteStatus(false)
                                },
                              },
                            },
                            [_vm._v("完成")]
                          )
                        : _vm._e(),
                      !_vm.isDeleteStatus
                        ? _c("c-icon", {
                            attrs: { name: "deleteIcon" },
                            on: {
                              click: function ($event) {
                                return _vm.changeDeleteStatus(true)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "history-list" },
              [
                !_vm.history.length
                  ? _c("empty-list", { attrs: { desc: "暂无搜索历史" } })
                  : _vm._e(),
                _vm._l(_vm.history, function (item, index) {
                  return _c(
                    "span",
                    {
                      key: "history" + index,
                      staticClass: "c-tag",
                      on: {
                        click: function ($event) {
                          return _vm.clickHistory(item)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "van-ellipsis tag-text" }, [
                        _vm._v(_vm._s(item.keyWord)),
                      ]),
                      _vm.isDeleteStatus
                        ? _c("van-icon", {
                            attrs: { name: "clear" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.deleteHistory(item.keyWord, index)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      !this.isShowRes && _vm.searchValue.length
        ? _c("div", { staticClass: "associate-list-wrapper" }, [
            _c(
              "div",
              { staticClass: "associate-list" },
              [
                !_vm.association.length
                  ? _c("empty-list", { attrs: { desc: "暂无联想结果" } })
                  : _vm._e(),
                _vm._l(_vm.association, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: "association" + index,
                      staticClass: "associate-item",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "system-title" },
                        [
                          _c("c-icon", { attrs: { name: "searchIcon" } }),
                          _vm._v(" " + _vm._s(item.systemName) + " "),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "associate-content-list" },
                        [
                          _vm._l(item.children, function (ass) {
                            return _c("div", {
                              key: ass.businessId,
                              staticClass: "associate-title van-ellipsis",
                              domProps: {
                                innerHTML: _vm._s(ass.highlightValue),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toResultDetail(ass, ass.systemId)
                                },
                              },
                            })
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "associate-title-more",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.lookMoreSuggestResult(
                                    item.systemId
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " 查看更多" + _vm._s(item.systemName) + " "
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm.isShowRes
        ? _c(
            "div",
            { staticClass: "result-list-wrapper" },
            [
              _c(
                "van-tabs",
                {
                  on: {
                    change: function ($event) {
                      return _vm.searchCategory()
                    },
                  },
                  model: {
                    value: _vm.active,
                    callback: function ($$v) {
                      _vm.active = $$v
                    },
                    expression: "active",
                  },
                },
                _vm._l(_vm.systemOptions, function (system, order) {
                  return _c(
                    "van-tab",
                    {
                      key: "systemOption" + order,
                      attrs: { title: system.text, name: system.value },
                    },
                    [
                      _c(
                        "van-list",
                        {
                          attrs: {
                            finished: !_vm.searchLoading,
                            "immediate-check": false,
                          },
                          model: {
                            value: _vm.searchLoading,
                            callback: function ($$v) {
                              _vm.searchLoading = $$v
                            },
                            expression: "searchLoading",
                          },
                        },
                        _vm._l(_vm.searchResult, function (item, index) {
                          return _c(
                            "van-cell",
                            {
                              key: "searchResult" + index,
                              staticClass: "result-wrapper",
                              on: {
                                click: function ($event) {
                                  return _vm.toResultDetail(item, _vm.active)
                                },
                              },
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "result-title van-multi-ellipsis--l2",
                                domProps: { innerHTML: _vm._s(item.title) },
                              }),
                              _c("div", {
                                staticClass:
                                  "result-content van-multi-ellipsis--l2",
                                domProps: { innerHTML: _vm._s(item.content) },
                              }),
                              _c("div", { staticClass: "result-desc" }, [
                                _c("div", [_vm._v(_vm._s(item.orgName))]),
                                _c("div", [_vm._v(_vm._s(item.createDate))]),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                      _vm.searchResult.length
                        ? _c(
                            "div",
                            {
                              staticClass: "look-more",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.lookMoreResult()
                                },
                              },
                            },
                            [_vm._v(" 查看更多 ")]
                          )
                        : _vm._e(),
                      !_vm.searchResult.length && !_vm.searchLoading
                        ? _c("empty-list", { attrs: { desc: "暂无搜索结果" } })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }