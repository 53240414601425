var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "div",
        {
          staticClass: "c-router-view",
          class: { "has-tabbar": _vm.$store.state.app.tabBars.opened },
        },
        [_c("router-view")],
        1
      ),
      _vm.$store.state.app.tabBars.opened
        ? _c(
            "van-tabbar",
            {
              attrs: { "active-color": "var(--themeColor)" },
              model: {
                value: _vm.$store.state.app.tabBars.active,
                callback: function ($$v) {
                  _vm.$set(_vm.$store.state.app.tabBars, "active", $$v)
                },
                expression: "$store.state.app.tabBars.active",
              },
            },
            [
              _c(
                "van-tabbar-item",
                {
                  attrs: { to: "/" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function (props) {
                          return [
                            _c("c-icon", {
                              attrs: {
                                name: props.active
                                  ? "homeSelectedIcon"
                                  : "homeIcon",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1013510223
                  ),
                },
                [_vm._v(" 首页 ")]
              ),
              _c(
                "van-tabbar-item",
                {
                  attrs: { to: "/notice" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function (props) {
                          return [
                            _c("c-icon", {
                              attrs: {
                                name: props.active
                                  ? "messageSelectedIcon"
                                  : "messageIcon",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3067027759
                  ),
                },
                [_vm._v(" 消息 ")]
              ),
              _c(
                "van-tabbar-item",
                {
                  attrs: { to: "/task" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function (props) {
                          return [
                            _c("c-icon", {
                              attrs: {
                                name: props.active
                                  ? "taskSelectedIcon"
                                  : "taskIcon",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1212471183
                  ),
                },
                [_vm._v(" 任务 ")]
              ),
              _c(
                "van-tabbar-item",
                {
                  attrs: { to: "/favorite" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function (props) {
                          return [
                            _c("c-icon", {
                              attrs: {
                                name: props.active
                                  ? "favoriteSelectedIcon"
                                  : "favoriteIcon",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1003282095
                  ),
                },
                [_vm._v(" 收藏 ")]
              ),
              _c(
                "van-tabbar-item",
                {
                  attrs: { to: "/qAnda" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function (props) {
                          return [
                            _c("c-icon", {
                              attrs: {
                                name: props.active
                                  ? "aiSelectedIcon"
                                  : "aiIcon",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    428842287
                  ),
                },
                [_vm._v(" 晓合 ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }