/**
 * 应用常量
 */
export const dashboardFilterType = {
  门户首页_监督检查_mob: '门户首页_监督检查_机构过滤_mob',
  门户首页_制度印发_mob: '门户首页_制度印发_机构过滤_mob',
};

export const associationSystemName = {
  LC_IE: '内部制度',
};
