import {
  FAQ_SEARCH,
  IE_SEARCH,
  PUNISH_SEARCH,
  REGULATORY_TRENDS_SEARCH,
  RI_SEARCH,
  RMS_SEARCH,
} from '../constants/mappings';

// 将key-value格式化
// export function formatLcIeSearch(data, mapper = {}) {
//     if (Array.isArray(data)) {
//         data.forEach((item, index) => {
//             if (typeof item !== 'object') {
//                 mapper = data;
//             } else if (Array.isArray(item)) {
//                 if (!mapper.length) {
//                     mapper = [];
//                 }
//                 mapper[index] = formatLcIeSearch(item);
//             } else if (Array.isArray(item.value)) {
//                 mapper[item.key] = formatLcIeSearch(item.value);
//             } else {
//                 mapper[item.key] = item.value;
//             }
//         })
//     } else if (Object.prototype.toString.call(data) === '[object Object]') {
//         mapper[data.key] = data.value;
//     }
//     return mapper;
// }

// 将对应mapping关系实现
export function mapperConstructData(data, mapper) {
  if (Object.prototype.toString.call(data) !== '[object Object]') {
    // eslint-disable-next-line no-throw-literal
    throw new Error();
  }
  const obj = {};
  // eslint-disable-next-line guard-for-in
  for (const key in mapper) {
    // obj[key] = data[mapper[key]];
    if (Object.prototype.hasOwnProperty.call(mapper, key)) {
      obj[key] = data[mapper[key]];
    }
  }
  return Object.assign(data, obj);
}

// 依据系统id来区分不同的映射关系
export function mapperSystemSearch(data, systemId) {
  if (systemId === 'LC_IE') {
    if (
      data[0] &&
      Object.prototype.toString.call(data[0].data) === '[object Array]'
    ) {
      return data[0].data.map((item) => mapperConstructData(item, IE_SEARCH));
    }
  } else if (systemId === 'LC_FAQ') {
    if (
      !!data.questionAnswers &&
      Object.prototype.toString.call(data.questionAnswers.data) ===
        '[object Array]'
    ) {
      return data.questionAnswers.data.map((item) =>
        mapperConstructData(item, FAQ_SEARCH));
    }
  } else if (systemId === 'LC_RMS') {
    if (
      data[0] &&
      Object.prototype.toString.call(data[0].data) === '[object Array]'
    ) {
      return data[0].data.map((item) => mapperConstructData(item, RMS_SEARCH));
    }
  } else if (systemId === 'LC_REGULATORY_TRENDS') {
    if (
      data[0] &&
      !!data[0].searchRespAll.searchRespVOList &&
      Object.prototype.toString.call(data[0].searchRespAll.searchRespVOList) ===
        '[object Array]'
    ) {
      return data[0].searchRespAll.searchRespVOList.map((item) =>
        mapperConstructData(item, REGULATORY_TRENDS_SEARCH));
    }
  } else if (systemId === 'LC_PUNISH') {
    if (
      data[0] &&
      !!data[0].searchRespAll.administrativePunish &&
      Object.prototype.toString.call(
        data[0].searchRespAll.administrativePunish,
      ) === '[object Array]'
    ) {
      return data[0].searchRespAll.administrativePunish.map((item) =>
        mapperConstructData(item, PUNISH_SEARCH));
    }
  } else if (systemId === 'LC_RI') {
    if (
      data[0] &&
      Object.prototype.toString.call(data[0].data) === '[object Array]'
    ) {
      return data[0].data.map((item) => mapperConstructData(item, RI_SEARCH));
    }
  }
  return [];
}
