import Vue from 'vue';
import CHeader from './common/c-header';
import CIcon from './common/c-icon';
import EmptyList from './common/empty-list';
import HHeader from 'hexasion-common-ui-vue-mobile/packages/components/h-header/h-header';

Vue.component('c-header', CHeader);
Vue.component('empty-list', EmptyList);
Vue.component('c-icon', CIcon);
Vue.component('h-header', HHeader);
