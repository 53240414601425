import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home/Home.vue';
import Search from '../views/home/search';
import AppList from '../views/home/app-list';

Vue.use(VueRouter);

// 解决编程式路由往同一地址跳转时会报错的情况 为解决搜索列表点击有时候报错问题
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  }
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
  },
  {
    path: '/app-list',
    name: 'appList',
    component: AppList,
  },
  {
    path: '/notice',
    component: () => import('../views/notice/notice.vue'),
    name: 'notice',
  },
  {
    path: '/notice/detail',
    name: 'noticeDetail',
    component: () => import('../views/notice/notice-detail.vue'),
  },

  {
    path: '/task',
    name: 'task',
    component: () => import('../views/task/task.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue'),
  },
  {
    path: '/favorite',
    name: 'favorite',
    component: () => import('../views/favorite/index.vue'),
  },
  {
    path: '/favorite/detail',
    name: 'favoriteDetail',
    component: () => import('../views/favorite/favorite-detail.vue'),
  },
  {
    path: '/childsystem',
    name: 'childsystem',
    component: () => import('../views/childsystem/childsystem.vue'),
  },
  {
    path: '/qAnda',
    name: 'qAnda',
    component: () => import('../views/qAnda/index.vue'),
  },
  {
    title: 'redictLogin',
    path: '/redictLogin',
    component: () => import('@/views/login/redictLogin'),
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
