var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-list-page" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "应用中心",
          "right-text": _vm.isEdit ? "保存" : "管理",
          "left-arrow": "",
        },
        on: { "click-left": _vm.onClickLeft, "click-right": _vm.onClickRight },
      }),
      _c(
        "div",
        { staticClass: "app-list-wrapper" },
        [
          _c("app-list-item", {
            attrs: {
              isEdit: _vm.isEdit,
              title: "常用功能",
              list: _vm.suggestFunctionList,
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function (ability) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "cut-icon",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.deleteFunction(ability)
                          },
                        },
                      },
                      [_c("span")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._l(_vm.allSystemFunctionList, function (item, index) {
            return _c("app-list-item", {
              key: "allSystemFunctionList" + index,
              attrs: {
                isEdit: _vm.isEdit,
                title: item.resourceName,
                list: item.children,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function (ability) {
                      return [
                        !ability.isSeleted
                          ? _c("van-icon", {
                              staticClass: "add-icon",
                              attrs: { name: "add" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.addFunction(ability, item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }