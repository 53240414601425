var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "empty-wrapper" }, [
    _c("img", { attrs: { src: require("../../assets/empty.png") } }),
    _c("div", { staticClass: "empty-desc" }, [_vm._v(_vm._s(_vm.desc))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }