/* eslint-disable no-param-reassign */
/* eslint-disable import/newline-after-import */
import { getVirtualGroupAccessReal } from '@/api/message';

const states = {
  messageUpdateEvent: localStorage.getItem('MESSAGE_UPDATE_EVENT') || 0,
  todoUpdateEvent: localStorage.getItem('TODO_UPDATE_EVENT') || 0,
  systemModuleArr: [],
};

const mutations = {
  SET_MESSAGE: (state, message) => {
    state.messageUpdateEvent = message;
  },
  SET_TODO: (state, todo) => {
    state.todoUpdateEvent = todo;
  },
  SET_SYSTEM_MODULE: (state, payload) => {
    state.systemModuleArr = payload;
  },
};

const actions = {
  setMessage({ commit }, MessageUpdateEvent) {
    commit('SET_MESSAGE', MessageUpdateEvent);
  },
  setTodo({ commit }, TodoUpdateEvent) {
    commit('SET_TODO', TodoUpdateEvent);
  },
  setSystemModule({ commit }, params) {
    return new Promise((resolve) => {
      getVirtualGroupAccessReal(params).then((res) => {
        const { code, result } = res;
        if (code === '0000000000') {
          const [data] = result;
          const systemModuleArr = data
            ? data.detailList.map(({ dictCode, dictName }) => ({
              label: dictCode,
              value: dictName,
            }))
            : [];
          commit('SET_SYSTEM_MODULE', systemModuleArr);
          resolve();
        }
      });
    });
  },
};

export default {
  namespaced: true,
  state: states,
  mutations,
  actions,
};
