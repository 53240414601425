/* eslint-disable no-param-reassign */
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time, 10);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/');
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time *= 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if ((`${time}`).length === 10) {
    time = parseInt(time, 10) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  /**
   •当天显示具体时间：HH:MM:

   •前一天显示昨天和具体时间：昨天 HH:MM ；

   •过去七天显示具体星期和时间：星期数 HH:MM；

   •其他时间显示日期：YYYY-MM-DD
   */
  if (option) {
    return parseTime(time, option);
  } else {
    if (diff < 3600 * 24) {
      return parseTime(time, '{h}:{i}');
    } else if (diff < 3600 * 24 * 2) {
      return parseTime(time, '昨天 {h}:{i}');
    } else if (diff < 3600 * 24 * 7) {
      return parseTime(time, '星期{a} {h}:{i}');
    }
    return parseTime(time, '{y}-{m}-{d}');
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ');
  if (!search) {
    return {};
  }
  const obj = {};
  const searchArr = search.split('&');
  searchArr.forEach((v) => {
    const index = v.indexOf('=');
    if (index !== -1) {
      const name = v.substring(0, index);
      const val = v.substring(index + 1, v.length);
      obj[name] = val;
    }
  });
  return obj;
}

/**
 * @param {string} date, fmt格式 例如=> 'yyyy-MM-dd'
 * @returns {string}
 */
export function formartDate(tmpDate, fmt) {
  if (!tmpDate) return '';
  let date;
  if (typeof tmpDate === 'string') {
    date = new Date(tmpDate);
  } else {
    date = tmpDate;
  }
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (`${date.getFullYear()}`).substr(4 - RegExp.$1.length),
    );
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : (`00${o[k]}`).substr((`${o[k]}`).length),
      );
    }
  }
  return fmt;
}

/**
 * @param {string} date, fmt格式 例如=> 'yyyy-MM-dd',并且如果年份是今年的话就把年份去除
 * @returns {string}
 */
export function formatCalendar(time) {
  let cFormat = '{y}.{m}.{d}';
  if (new Date(time).getFullYear() === new Date().getFullYear()) {
    cFormat = '{m}.{d}';
  }
  return parseTime(time, cFormat);
}

/* 判断客户端 */
export function judgeClient() {
  let client = '';
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { // 判断iPhone|iPad|iPod|iOS
    client = 'IOS';
  } else if (/(Android)/i.test(navigator.userAgent)) { // 判断Android
    client = 'ANDROID';
  } else {
    client = 'PC';
  }
  return client;
}
