<template>
  <div class="search-page">
    <van-nav-bar
      :right-text="isShowRes ? '取消' : '搜索'"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #title>
        <form action="javascript: return true">
          <van-search
              v-model.trim="searchValue"
              @input="getSearchSuggest()"
              shape="round"
              :clearable="false"
              placeholder="请输入关键词搜索"
              @search="clickSearchBtn()"
              autoFocus
          >
            <template #left-icon>
              <c-icon name="searchIcon" class="search-icon" />
            </template>
            <template #right-icon>
              <c-icon
                  name="clearIcon"
                  class="search-icon"
                  v-if="searchValue !== ''"
                  @click="clearSearchValue()"
              />
            </template>
          </van-search>
        </form>
      </template>
    </van-nav-bar>
    <!--    历史记录-->
    <div class="history-list-wrapper" v-if="!isShowRes && !searchValue.length">
      <div class="history-title" v-if="history.length">
        历史搜索
        <div class="delete-text">
          <span class="clear-all" v-if="isDeleteStatus" @click="confirmDelete()"
            >清空全部</span
          >
          <span v-if="isDeleteStatus" @click="changeDeleteStatus(false)"
            >完成</span
          >
          <c-icon
            v-if="!isDeleteStatus"
            name="deleteIcon"
            @click="changeDeleteStatus(true)"
          />
        </div>
      </div>
      <div class="history-list">
        <empty-list desc="暂无搜索历史" v-if="!history.length"></empty-list>
        <span
          class="c-tag"
          @click="clickHistory(item)"
          v-for="(item, index) in history"
          :key="'history' + index"
        >
          <span class="van-ellipsis tag-text">{{ item.keyWord }}</span>
          <van-icon
            v-if="isDeleteStatus"
            name="clear"
            @click.stop="deleteHistory(item.keyWord, index)"
          />
        </span>
      </div>
    </div>
    <!--    搜索联想-->
    <div class="associate-list-wrapper" v-if="!this.isShowRes && searchValue.length">
      <div class="associate-list">
        <empty-list desc="暂无联想结果" v-if="!association.length"></empty-list>
        <div
          class="associate-item"
          v-for="(item, index) in association"
          :key="'association' + index"
        >
          <div class="system-title">
            <c-icon name="searchIcon" />
            {{ item.systemName }}
          </div>
          <div class="associate-content-list">
            <div
              class="associate-title van-ellipsis"
              v-for="(ass) in item.children"
              :key="ass.businessId"
              v-html="ass.highlightValue"
              @click="toResultDetail(ass, ass.systemId)"
            ></div>
            <div
              class="associate-title-more"
              @click.stop="lookMoreSuggestResult(item.systemId)"
            >
              查看更多{{ item.systemName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    搜索结果-->
    <div class="result-list-wrapper" v-if="isShowRes">
      <van-tabs @change="searchCategory()" v-model="active">
        <van-tab
          :title="system.text"
          :name="system.value"
          v-for="(system, order) in systemOptions"
          :key="'systemOption' + order"
        >
          <van-list
            v-model="searchLoading"
            :finished="!searchLoading"
            :immediate-check="false"
          >
            <van-cell
              class="result-wrapper"
              v-for="(item, index) in searchResult"
              :key="'searchResult' + index"
              @click="toResultDetail(item, active)"
            >
              <div
                class="result-title van-multi-ellipsis--l2"
                v-html="item.title"
              ></div>
              <div
                class="result-content van-multi-ellipsis--l2"
                v-html="item.content"
              ></div>
              <div class="result-desc">
                <div>{{ item.orgName }}</div>
                <div>{{ item.createDate }}</div>
              </div>
            </van-cell>
          </van-list>
          <div
            class="look-more"
            v-if="searchResult.length"
            @click.stop="lookMoreResult()"
          >
            查看更多
          </div>
          <empty-list
            v-if="!searchResult.length && !searchLoading"
            desc="暂无搜索结果"
          ></empty-list>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import { closeTabBarsMixins, toSystemPageMixins } from '../../utils/mixins';
import {
  cleanAllHistory,
  deleteHistory,
  getSearchHistory,
  getSearchSuggest,
  saveHistory,
  searchCategory, selectSystemBySearchKey,
} from '../../api/search';
import { mapperSystemSearch } from '../../utils/mapping';
import { associationSystemName } from '../../constants';

export default {
  name: 'Search',
  mixins: [closeTabBarsMixins, toSystemPageMixins],
  data() {
    return {
      searchValue: '',
      active: null,
      history: [], // 历史记录
      isDeleteStatus: false, // 是否处于编辑状态下
      association: [], // 联想记录
      isShowRes: false,
      systemOptions: [], // 子系统的名称及id数组
      searchLoading: false,
      searchResult: [], // 搜索结果
    };
  },
  beforeRouteEnter(to, from, next) {
    // 在渲染该组件的对应路由被 confirm 前调用
    // 不！能！获取组件实例 `this`
    // 因为当守卫执行前，组件实例还没被创建
    next((vm) => {
      if (typeof to.query.searchValue === 'string') {
        vm.searchValue = to.query.searchValue;
        vm.searchCategory();
      }
    });
  },
  created() {
    this.getSearchHistory();
    // 获取子系统数据
    const dict = JSON.parse(sessionStorage.getItem('dist_info'));
    for (const key in dict.COMBINEDSEARCH.LC_MOB.children) {
      if (Object.prototype.hasOwnProperty.call(dict.COMBINEDSEARCH.LC_MOB.children, key)) {
        this.systemOptions.push({
          text: dict.COMBINEDSEARCH.LC_MOB.children[key],
          value: key,
        });
      }
    }
    this.active = this.systemOptions[0].value;
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    onClickRight() {
      if (this.isShowRes) {
        this.onClickLeft();
      } else {
        this.clickSearchBtn();
      }
    },
    changeDeleteStatus(bool) {
      this.isDeleteStatus = bool;
    },
    confirmDelete() {
      Dialog.confirm({
        title: '确认删除全部历史搜索？',
      })
        .then(() => {
          // on confirm
          this.cleanAllHistory();
        })
        .catch(() => {
          // on cancel
        });
    },
    // 联想
    getSearchSuggest() {
      const dict = JSON.parse(sessionStorage.getItem('dist_info'));
      this.isShowRes = false;
      getSearchSuggest({
        keyWord: this.searchValue,
        limit: 8,
        systemIdList: this.systemOptions.map((item) => item.value),
      }).then((res) => {
        if (res.code === '0000000000') {
          this.association = [];
          res.result.forEach((item) => {
            const system = this.association.find(
              (ass) => ass.systemId === item.systemId,
            );
            if (system) {
              system.children.push(item);
            } else {
              this.association.push({
                systemId: item.systemId,
                systemName:
                  associationSystemName[item.systemId] ||
                  dict.COMBINEDSEARCH.LC_MOB.children[item.systemId],
                children: [item],
              });
            }
          });
        }
      });
    },
    // 清空搜索关键字
    clearSearchValue() {
      this.searchValue = '';
      this.isShowRes = false;
    },
    // 获取搜索历史
    getSearchHistory() {
      getSearchHistory({ pageNum: 1, pageSize: 10 }).then((res) => {
        if (res.code === '0000000000') {
          this.history = res.result.records;
        }
      });
    },
    // 保存历史记录
    saveHistory() {
      if (!this.searchValue.length) {
        return;
      }
      saveHistory({ keyWord: this.searchValue }).then(() => {
        this.getSearchHistory();
      });
    },
    // 点击单个历史记录
    clickHistory(history) {
      this.searchValue = history.keyWord;
      this.getSearchSuggest();
    },
    // 单个删除历史记录
    deleteHistory(keyWord, index) {
      deleteHistory(keyWord).then((res) => {
        if (res.code === '0000000000') {
          this.history.splice(index, 1);
        }
      });
    },
    // 清空历史记录
    cleanAllHistory() {
      cleanAllHistory().then((res) => {
        if (res.code === '0000000000') {
          this.history = [];
        }
      });
    },
    // 点击搜索按钮触发事件
    clickSearchBtn(active) {
      this.$router.replace({
        path: '/search',
        query: { searchValue: this.searchValue },
      });
      // 获取最佳匹配系统
      selectSystemBySearchKey({ keyWord: this.searchValue }).then((res) => {
        if (res.code === '0000000000') {
          this.active = active || res.result || this.active;
        }
        this.searchCategory();
      });
      this.saveHistory();
    },
    // 搜索
    searchCategory() {
      // if (!this.searchValue.trim()) {
      //   return;
      // }

      this.searchLoading = true;
      this.searchResult = [];
      this.isShowRes = true;
      searchCategory({
        keyWord: this.searchValue,
        pageNum: 1,
        pageSize: 10,
        systemId: this.active,
        displaySize: 60,
        precise: true,
      }).then((res) => {
        if (res.code === '0000000000') {
          this.searchResult = mapperSystemSearch(
            JSON.parse(res.result.data),
            this.active,
          );
        }
        this.searchLoading = false;
      });
    },
    // 点击更多联想结果
    lookMoreSuggestResult(systemId) {
      this.active = systemId;
      this.clickSearchBtn(systemId);
    },
    // 点击查看更多搜索结果
    lookMoreResult() {
      const baseUrl = this.getSystemPath(this.active);
      const systemSearchPathMap = {
        // 制度引擎
        LC_IE: `/searchResult?key=${this.searchValue}`,
        // 合规FAQ
        LC_FAQ: `/legalList?keyword=${encodeURI(
          this.searchValue,
        )}&from=platform`,
        LC_RMS: '',
        // 监管动态
        LC_REGULATORY_TRENDS: `/result?from=normal&keyword=${this.searchValue}`,
        // 同业罚单
        LC_PUNISH: `/result?from=normal&keyword=${this.searchValue}`,
        // 监管法规（外规内化）
        LC_RI: `/searchResult?key=${this.searchValue}`,
      };
      this.toSystemFrame(baseUrl, systemSearchPathMap[this.active]);
    },
    // 搜索结果跳转
    toResultDetail(result, systemId) {
      // title, id cid 是搜索的结果，businessId value cid是联想结果
      const { id, businessId, cid } = result;
      const baseUrl = this.getSystemPath(systemId);
      const systemDetailPathMap = {
        // 制度引擎
        LC_IE: `/instdetail?instId=${id || businessId}`,
        // 合规FAQ
        LC_FAQ: `/legaldetail?keyword=${encodeURI(
          this.searchValue,
        )}&cid=${cid}&qid=${id || businessId}&from=platform`,
        LC_RMS: '',
        // 监管动态
        LC_REGULATORY_TRENDS: `/detail/${id || businessId}`,
        // 同业罚单
        LC_PUNISH: `/detail/${id || businessId}`,
        // 监管法规（外规内化）
        LC_RI: `/systemDetails?id=${id || businessId}`,
      };
      this.toSystemFrame(baseUrl, systemDetailPathMap[systemId]);
    },
  },
};
</script>

<style lang="scss" scoped>
.van-hairline--bottom::after {
  border: none;
}

.van-tab__pane,
.van-tab__pane-wrapper {
  height: calc(100vh - 92px);
  background: #f9f9f9;
  overflow: scroll;
}

.van-nav-bar ::v-deep {
  .van-nav-bar__text {
    font-size: 14px;
    color: #333333;
    font-weight: 400;
  }

  .van-icon {
    color: #999;
  }

  .van-nav-bar__left {
    padding: 0 8px;
  }

  .van-nav-bar__arrow {
    color: #000;
    font-size: 20px;
    padding: 0;
  }

  .van-nav-bar__title {
    margin: 0 58px 0 35px;
    width: 100%;
    max-width: unset;
  }
}

.van-search {
  padding: 7px 0;
  .van-cell {
    font-weight: 500;
    line-height: unset;
  }
  .search-icon {
    color: #c5c5c5;
    display: flex;
  }
  ::v-deep {
    .van-field__left-icon {
      display: flex;
      align-items: center;
    }
  }
}

.search-page {
  background: #fff;
  height: 100%;
}


.history-list-wrapper,
.associate-list-wrapper {
  margin-top: 20px;
  padding: 0 15px;
}

.history-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  line-height: 20px;

  img {
    width: 16px;
    height: 16px;
  }
}

.delete-text {
  font-weight: 400;
  color: var(--themeColor);
  height: 16px;

  .clear-all {
    margin-right: 10px;
  }
}

.history-list {
  text-align: left;

  .tag-text {
    max-width: 100%;
    word-break: break-all;
    display: block;
  }
}

.tag-more {
  padding: 5px 8px;
}

.associate-list {
}

.associate-item {
  display: flex;
  align-items: start;
}

.system-title {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #323233;
  line-height: 20px;
  margin: 10px 15px 0 0;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    color: rgba(0, 0, 0, 0.2);
    margin-right: 4px;
  }
}

.associate-content-list {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 20px;
  flex: 1;
  width: 0;
}

.associate-title,
.associate-title-more {
  color: #333333;
  padding: 10px 0;
  border-bottom: 1px solid #ebedf0;
}

.associate-title-more {
  color: #999;
}

.result-wrapper {
  padding: 10px 15px;
  margin-top: 10px;
  background: #fff;
  text-align: left;
}

.result-title {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  line-height: 20px;
}

.result-content {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 17px;
  margin: 8px 0;
}

.result-desc {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  align-items: flex-start;
  div:first-of-type {
    flex: 1;
    white-space: nowrap; /* 防止内容换行 */
    overflow: hidden; /* 隐藏超出部分 */
    text-overflow: ellipsis; /* 显示省略号 */
  }

  div:last-of-type {
    font-size: 10px;
    width: 75px;
    text-align: right;
  }
}

.look-more {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #333333;
  line-height: 16px;
  padding: 13px 0 20px;
  background: #fff;
  margin-top: 1px;
}
</style>
