export const IE_SEARCH = {
  title: 'title',
  content: 'contentFragment',
  orgName: 'publishOrgName',
  createDate: 'publishDate',
  id: 'instId',
  cid: 'pdfContentId',
  viewCount: 'viewCount',
  effectiveDate: 'effectiveDate',
};

// 暂不了解id使用哪个id
export const FAQ_SEARCH = {
  title: 'question',
  content: 'answer',
  orgName: 'path',
  createDate: 'modifiedTime',
  id: 'qid',
  viewCount: 'discussionCount',
  effectiveDate: 'modifiedTime',
  cid: 'cid',
};

// rms
export const RMS_SEARCH = {
  title: 'title',
  content: 'highlightContent',
  orgName: 'publishOrg',
  createDate: 'publishDate',
  id: 'regId',
  viewCount: 'issueNum',
};

// LC_REGULATORY_TRENDS
export const REGULATORY_TRENDS_SEARCH = {
  title: 'title',
  content: 'highlightContent',
  orgName: 'publishChannel',
  createDate: 'publishDate',
  id: 'regulatoryTrendsId',
};

// LC_PUNISH
export const PUNISH_SEARCH = {
  title: 'title',
  content: 'summary',
  orgName: 'punishedOrg',
  createDate: 'punishmentDate',
  id: 'id',
};

// LC_RI
export const RI_SEARCH = {
  title: 'title',
  content: 'highlightContent',
  orgName: 'publishOrg',
  createDate: 'publishDate',
  id: 'regId',
};
